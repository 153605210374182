<!--
 * @Author: jiang
 * @Date: 2021-07-18 10:29:33
 * @Description: 详情
-->
<template>
  <div
    class="chart"
    ref="chart"
  ></div>
</template>

<script>
export default {
  props: {
    option: Object,
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    option: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.update()
          })
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.chart = this.$echarts.init(this.$refs.chart)

      const option = {
        title: {
          text: '122',
          subtext: 'sub',
          top: 20,
          left: 30,
        },
        grid: {
          top: 100,
          right: 40,
          bottom: 30,
          left: 40,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        color: ['#36CFC9'],
        series: [
          {
            data: [],
            type: 'bar',
            barCategoryGap: '65%',
            showBackground: true,
            label: {
              show: true,
              position: 'top',
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          },
        ],
      }

      this.chart.setOption(option)
    },
    update() {
      const option = this.option
      this.chart.setOption(option)
    },
  },
}
</script>

<style>
.chart {
  width: 100%;
  height: 100%;
}
</style>
